import style from "./index.module.less";
import Image from "next/image";
const Index = () => {
  const dataList = [
    {
      img: "/images/home/creator/c-1.webp",
      title: "Unlimited Removals",
      desc: "All leaks are delisted and requested for removal.",
    },
    {
      img: "/images/home/creator/c-2.webp",
      title: "Constant Scans",
      desc: "Constant scans means leaks can’t spread.",
    },
    {
      img: "/images/home/creator/c-3.webp",
      title: "Anonymity",
      desc: "Erasa takes extra precaution to keep your identity hidden.",
    },
    {
      img: "/images/home/creator/c-4.webp",
      title: "Worldwide Scans",
      desc: "The AI targets leaks shared everywhere.",
    },
  ];
  return (
    <div className={style.container}>
      <h2>Creator-Friendly</h2>
      <div className={style.main}>
        {dataList.map((item, key) => (
          <div key={key}>
            <Image
              src={item.img}
              height={114}
              width={114}
              alt={item.title}
            ></Image>
            <div>{item.title}</div>
            <div>{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
