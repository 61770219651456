import React, { useEffect, useState } from "react";
import { CommonBackContent } from "../Layouts/CommonBackContent";
import { useConfigProvider } from "@/context/ConfigProvider";
import { useUserProvider } from "@/context/UserProvider";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import styles from "./index.module.less";
import { Button } from "antd";
import ProtextOrigin from "./protectOriginal/index";

import FreQuestion from "./freQuestions/index";
import Image from "next/image";

import AiPower from "./aiPower/index";
import AiShowGrid from "./aiShowGrid";
import CreaterFriend from "./CreaterFriend/index";
import BottomFolder from "./BottomFolder";
import AddAccountModal from "@/components/protectedAccount/AddAcountModal";
const HomeContent = ({ data }: { data: any }) => {
  const { isMobile } = useConfigProvider();
  const router = useRouter();
  const [isAddAccountModal, setIsAddAccountModal] = useState(false);
  const handleGetStart = () => {
    setIsAddAccountModal(true);
  };
  let style = {
    padding: "0px",
  };
  return (
    <div>
      <CommonBackContent>
        <div className={styles.topMianLogo}>
          <div className={styles.left}>
            <p>AI-Driven</p>
            <p>Content Protection</p>
            <p>The Ultimate Leak Removal Solution for Creators</p>
            <div className={styles.indicator}>
              <div className={styles.l1}>
                <span style={{ color: "#478FFF" }}>
                  <span style={{ animationDelay: "0.1s" }}>2</span>
                  <span style={{ animationDelay: "0.2s" }}>1</span>
                  <span style={{ animationDelay: "0.3s" }}>0</span>
                  <span style={{ animationDelay: "0.4s" }}>0</span>
                </span>
                <span>leaks found</span>
              </div>
              <div className={styles.l2}>
                <span style={{ color: "#8789FC" }}>
                  <span style={{ animationDelay: "0.4s" }}>1</span>
                  <span style={{ animationDelay: "0.5s" }}>8</span>
                  <span style={{ animationDelay: "0.6s" }}>9</span>
                  <span style={{ animationDelay: "0.7s" }}>0</span>
                </span>
                <span>Google Removed</span>
              </div>
              <div className={styles.l3}>
                <span style={{ color: "#F84DAD" }}>
                  <span style={{ animationDelay: "0.8s" }}>1</span>
                  <span style={{ animationDelay: "0.9s" }}>6</span>
                  <span style={{ animationDelay: "1s" }}>8</span>
                  <span style={{ animationDelay: "1.1s" }}>0</span>
                </span>
                <span>X(Twitter) Removed</span>
              </div>
            </div>
            <Button className={styles.btn} onClick={handleGetStart}>
              Get Started Free
            </Button>
          </div>
          {!isMobile && (
            <div className={styles.right}>
              <Image
                src={"/images/home/home_right.webp"}
                width={451}
                height={517}
                layout="responsive"
                alt="erasa"
              ></Image>
            </div>
          )}
        </div>
      </CommonBackContent>
      <ProtextOrigin></ProtextOrigin>
      <AiPower></AiPower>
      <AiShowGrid></AiShowGrid>
      <CreaterFriend></CreaterFriend>
      <CommonBackContent style={{ padding: "1rem" }}>
        <FreQuestion></FreQuestion>
      </CommonBackContent>
      <BottomFolder onOpenModal={handleGetStart}></BottomFolder>
      <AddAccountModal
        isModalOpen={isAddAccountModal}
        handleCancel={() => setIsAddAccountModal(false)}
        isHome={true}
      ></AddAccountModal>
    </div>
  );
};

export default HomeContent;
