import { useEffect, useRef } from "react";
import styles from "./index.module.less";
const Index = () => {
  return (
    <div className={styles.container}>
      <div>
        <span>The</span>
        <span> AI-powered</span>
      </div>
      <div>Leak Detect &Removal</div>
      <div className={styles.main}>
        <video
          src="https://res-front.pumpsoul.com/prod-erasa/videos/aiPower.mp4"
          autoPlay
          loop
          muted
        ></video>
      </div>
    </div>
  );
};

export default Index;
